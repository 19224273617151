import { useBoolean } from "@biblioteksentralen/react";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { mergeObjectsConcatenatingArrays } from "../../../utils/mergeObjectsConcatenatingArrays";
import { emptySearchResults, SearchFilter, SearchResults } from "../../types";

export interface SearchContext {
  current: {
    query: string | undefined;
    relevantFiltersForQuery: SearchFilter[] | undefined;
    filter: SearchFilter | undefined;
    results: SearchResults;
  };
  setSearchQuery: Dispatch<SetStateAction<string | undefined>>;
  setSearchFilter: Dispatch<SetStateAction<SearchFilter | undefined>>;
  setSearchResults: Dispatch<SetStateAction<SearchResults>>;
  setRelevantFiltersForQuery: Dispatch<SetStateAction<SearchFilter[] | undefined>>;
  updateSearchResults: (updatedResults: Partial<SearchResults>) => void;
  loading: boolean;
  onLoading: () => void;
  onLoadingFinished: () => void;
}

const noop = () => {};

export const emptySearchContext = {
  current: {
    query: undefined,
    filter: undefined,
    results: emptySearchResults,
    relevantFiltersForQuery: undefined,
  },
  setSearchQuery: noop,
  setSearchFilter: noop,
  setSearchResults: noop,
  setRelevantFiltersForQuery: noop,
  updateSearchResults: noop,
  loading: false,
  onLoading: noop,
  onLoadingFinished: noop,
};

export const SearchContext = createContext<SearchContext>(emptySearchContext);

export const useSearchContext = () => useContext(SearchContext);

type SearchContextProviderProps = {
  children: ReactNode;
};

export const SearchContextProvider = ({ children }: SearchContextProviderProps) => {
  const [searchQuery, setSearchQuery] = useState<string | undefined>(emptySearchContext.current.query);
  const [searchFilter, setSearchFilter] = useState<SearchFilter | undefined>(emptySearchContext.current.filter);
  const [searchResults, setSearchResults] = useState<SearchResults>(emptySearchContext.current.results);
  const [relevantFiltersForQuery, setRelevantFiltersForQuery] = useState<SearchFilter[] | undefined>(undefined);

  const [elementIdToFocus, setElementIdToFocus] = useState<string | undefined>();
  const [loading, { on: onLoading, off: onLoadingFinished }] = useBoolean();
  const notFirstUpdateRef = useRef<boolean>();

  const updateFocusState = useCallback((updatedResults: Partial<SearchResults>) => {
    const firstNewWork = updatedResults.works?.items[0];
    const firstNewAgent = updatedResults.agents?.items[0];
    firstNewWork && setElementIdToFocus(`bibbi-work:${firstNewWork?.id}`);
    firstNewAgent && setElementIdToFocus(`bibbi-agent:${firstNewAgent?.id}`);
  }, []);

  const updateSearchResults = useCallback(
    (updatedResults: Partial<SearchResults>) => {
      // We don't want to remove duplicates here (thus the undefined equality comparison function).
      // The resulting lower number due to removed duplicates will confuse the search pagination,
      // leading to an infinite loop. It's better to keep the duplicates and deal with them downstream.
      setSearchResults((currentResults) =>
        mergeObjectsConcatenatingArrays(currentResults, updatedResults as SearchResults, undefined)
      );

      updateFocusState(updatedResults);
    },
    [updateFocusState]
  );

  useEffect(() => {
    if (elementIdToFocus) {
      // Using the ref to not update the focus state on initial setSearchResults
      if (!notFirstUpdateRef.current) {
        notFirstUpdateRef.current = true;
        return;
      }
      const elementToFocus = document.getElementById(elementIdToFocus);
      elementToFocus?.focus();
    }
  }, [elementIdToFocus]);

  return (
    <SearchContext.Provider
      value={{
        current: {
          query: searchQuery,
          filter: searchFilter,
          relevantFiltersForQuery,
          results: searchResults,
        },
        setSearchQuery,
        setRelevantFiltersForQuery,
        setSearchResults,
        updateSearchResults,
        loading,
        onLoading,
        onLoadingFinished,
        setSearchFilter,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
