import createClient from "openapi-fetch";
import { configurationPaths, patronPaths, searchPaths, libraryPaths } from "../types/index";
import {
  PatronReservation,
  RediaPlatformProps,
  RediaPlatform,
  Publication,
  SessionConfiguration,
  Holdings,
  RediaPlatformEnvironment,
  Schemas,
} from "./interfaces";
import { RediaPlatformError, RediaPlatformPatronApiError, Unauthenticated } from "./errors";
import { standardQuerySerializer } from "./querySerializer";
import { z } from "zod";
import { isJestTest } from "@libry-content/common";
import { RediaPlatformSessionMiddleware } from "./RediaPlatformSessionMiddleware";
import { getRediaPlatformBaseUrl } from "./getRediaPlatformBaseUrl";
import { SessionStore } from "./sessionStore";

const rediaPlatformConfigSchema = z.object({
  environment: z.enum(["dev", "staging", "prod"]),
  clientId: z.string(),
});

const rediaPlatformConfig = rediaPlatformConfigSchema.safeParse({
  environment: process.env.NEXT_PUBLIC_REDIA_PLATFORM_ENVIRONMENT,
  clientId: process.env.NEXT_PUBLIC_REDIA_PLATFORM_CLIENT_ID,
});

if (!rediaPlatformConfig.success && !isJestTest) {
  console.warn(`Redia Platform integration not enabled`, rediaPlatformConfig.error);
}

export const createRediaPlatformClient = (props: RediaPlatformProps): RediaPlatform => new RediaPlatformClient(props);

interface RediaPlatformApis {
  configuration: ReturnType<typeof createClient<configurationPaths>>;
  patron: ReturnType<typeof createClient<patronPaths>>;
  search: ReturnType<typeof createClient<searchPaths>>;
  library: ReturnType<typeof createClient<libraryPaths>>;
}

class RediaPlatformClient implements RediaPlatform {
  public readonly isMock = false;
  public environment: RediaPlatformEnvironment;
  private apis: RediaPlatformApis;
  private sessionMiddleware: RediaPlatformSessionMiddleware;
  private sessionStore: SessionStore;

  constructor(props: RediaPlatformProps) {
    if (!rediaPlatformConfig.success)
      throw new Error(
        "Missing environment variables NEXT_PUBLIC_REDIA_PLATFORM_ENVIRONMENT or NEXT_PUBLIC_REDIA_PLATFORM_CLIENT_ID"
      );
    const { customerId, sessionStore, onSessionExpired } = props;
    const { clientId, environment } = rediaPlatformConfig.data;
    this.environment = environment;
    this.sessionStore = sessionStore;
    this.sessionMiddleware = new RediaPlatformSessionMiddleware({
      environment,
      sessionStore,
      clientId,
      customerId,
      onSessionExpired,
    });
    const fetchWithSession = this.sessionMiddleware.getFetch();
    this.apis = {
      configuration: createClient<configurationPaths>({
        baseUrl: getRediaPlatformBaseUrl({ api: "configuration", environment }),
        fetch: fetchWithSession,
        querySerializer: standardQuerySerializer,
      }),
      patron: createClient<patronPaths>({
        baseUrl: getRediaPlatformBaseUrl({ api: "patron", environment }),
        fetch: fetchWithSession,
        querySerializer: standardQuerySerializer,
      }),
      search: createClient<searchPaths>({
        baseUrl: getRediaPlatformBaseUrl({ api: "search", environment }),
        fetch: fetchWithSession,
        querySerializer: standardQuerySerializer,
      }),
      library: createClient<libraryPaths>({
        baseUrl: getRediaPlatformBaseUrl({ api: "library", environment }),
        fetch: fetchWithSession,
        querySerializer: standardQuerySerializer,
      }),
    };
  }

  public async login(username: string, password: string) {
    // Start en fersk sesjon før innlogging slik at sesjonslengden samsvarer med
    // hvor lenge en har vært innlogget. Vi kan evt. droppe dette senere hvis vi
    // tar i bruk refreshToken.
    this.sessionMiddleware.clearSession();

    const { data, error } = await this.apis.patron.POST("/api/patron/authentication", {
      body: {
        cardIdentifier: username,
        password: password,
      },
    });
    if (error) {
      throw new RediaPlatformPatronApiError(error);
    }
    const loginTime = new Date();
    const expiresStr = this.sessionStore.get()?.token?.expiresTime;
    const expiresTime = expiresStr ? new Date(expiresStr) : undefined;
    if (expiresTime) {
      const sessionTimeLeft = (expiresTime?.getTime() - loginTime.getTime()) / 1000;
      if (sessionTimeLeft < 1000) {
        console.error(
          `Got unusually short session: ${sessionTimeLeft} seconds. Session expires: ${expiresTime.toISOString()}. Login time: ${loginTime.toISOString()}`
        );
      } else {
        console.info(`Session time left: ${sessionTimeLeft} seconds`);
      }
    }
    // Store user and login time
    this.sessionStore.patch({ user: data.patron, loginTime: loginTime.toISOString() });
  }

  public async logout() {
    // Update user state *first*, to avoid race conditions where requests
    // based on the user session is starting while we're logging out.
    this.sessionStore.patch({ user: null, loginTime: null });
    const { error } = await this.apis.patron.GET("/api/patron/deauthenticate", {});
    if (error) {
      console.error("Failed to logout user from Redia Platform", error);
      // Her kan vi evt. la være å tømme den lokale sesjonen og la brukeren
      // prøve på nytt. Men risikoen for at det feiler på nytt er vel stor, og
      // da bli en sittende med en lokal sesjon uten mulighet til å logge ut. Så
      // kanskje tross alt beste å slette den lokale sesjonen. Sesjonen hos
      // Redia vil jo uansett ikke vare mer enn 30 minutter.
      this.sessionMiddleware.clearSession();
    }
  }

  public getUser() {
    return this.sessionMiddleware.getSession()?.user ?? undefined;
  }

  public async getConfiguration(): Promise<SessionConfiguration> {
    const { data, error } = await this.apis.configuration.GET("/api/session/configuration", {});
    if (error) throw new RediaPlatformError(error);
    return data.configuration;
  }

  private handlePatronError(error: Schemas["patron"]["Errors"]) {
    return !this.getUser() ? new Unauthenticated() : new RediaPlatformError(error);
  }

  public async refreshUserProfile() {
    const { data } = await this.apis.patron.GET("/api/patron/information", {});
    if (data) {
      // Check that we have not been logged out (or logged in as another user) since the request started
      if (data.patron.patronId === this.getUser()?.patronId) {
        this.sessionStore.patch({ user: data.patron });
        return data.patron;
      }
    }
    return undefined;
  }

  public async getReservations(): Promise<PatronReservation[]> {
    const { data, error } = await this.apis.patron.GET("/api/patron/reservation", {});
    if (error) throw this.handlePatronError(error);
    return data.reservations;
  }

  public async deleteReservation(reservationId: string) {
    const { error } = await this.apis.patron.DELETE("/api/patron/reservation/{reservationId}", {
      params: {
        path: { reservationId },
      },
    });
    if (error) throw this.handlePatronError(error);
  }

  public async createReservation(reservationId: string, pickupBranchCode: string) {
    const { data, error } = await this.apis.patron.POST("/api/patron/reservation", {
      body: {
        reservationId: reservationId,
        pickupBranchCode: pickupBranchCode,
      },
    });
    if (error) throw this.handlePatronError(error);
    return data.reservation;
  }

  public async getLoans() {
    const { data, error } = await this.apis.patron.GET("/api/patron/loan", {});
    if (error) throw this.handlePatronError(error);
    return data.loans;
  }

  public async renewLoan(loanId: string) {
    const { data, error } = await this.apis.patron.PUT("/api/patron/loan/{loanId}", {
      params: {
        path: { loanId },
      },
    });
    if (error) throw this.handlePatronError(error);
    return data.loan;
  }

  public async getBranches() {
    const { data, error } = await this.apis.library.GET("/api/branches/information", {
      params: {},
    });
    if (error) throw new RediaPlatformError(error);
    return data.branches;
  }

  public async getBranch(branchCode: string) {
    const { data, error } = await this.apis.library.GET("/api/branches/information", {
      params: { query: { branches: [branchCode] } },
    });
    if (error) throw new RediaPlatformError(error);
    return data.branches[branchCode];
  }

  public async getPublications(publicationIds: string[]): Promise<Record<string, Publication | undefined>> {
    if (publicationIds.length === 0) return {};
    const { data, error } = await this.apis.search.GET("/api/publication", {
      params: {
        query: {
          publication_ids: publicationIds,
        },
      },
    });
    if (error) throw new RediaPlatformError(error);
    return data.publications;
  }

  public async getHoldings(publicationIds: string[]): Promise<Record<string, Holdings | undefined>> {
    if (publicationIds.length === 0) return {};
    const { data, error } = await this.apis.library.GET("/api/holdings", {
      params: {
        query: {
          publication_ids: publicationIds,
          show_to: ["public"],
        },
      },
    });
    if (error) throw new RediaPlatformError(error);
    return data.holdings;
  }
}
