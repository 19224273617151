import { Box, Flex, HStack, Link, colors } from "@biblioteksentralen/react";
import { splashSiteUrl } from "@libry-content/common";
import { useTranslation } from "../../../utils/hooks/useTranslation";

function LibryContentLinks() {
  const { t } = useTranslation();
  return (
    <Flex background={colors.grey80} color="white" justifyContent="center" padding=".5rem">
      <HStack spacing="1.5rem" justifyContent="center" opacity="0.6">
        <Box>
          {t("Bygget med")} {/* eslint-disable-next-line i18next/no-literal-string */}
          <Link href={splashSiteUrl} translate="no">
            Libry Content
          </Link>
        </Box>
        <Box>
          <Link href="/cms/" rel="nofollow">
            {t("Gå til editor")}
          </Link>
        </Box>
      </HStack>
    </Flex>
  );
}

export default LibryContentLinks;
