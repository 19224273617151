import { Agent, Person, Work } from "@biblioteksentralen/cordata";
import { ElasticsearchAgentResponse, ElasticsearchWorksResponse } from "@biblioteksentralen/search-models";
import { LanguageCode } from "@libry-content/localization";
import { ResolvedEvent } from "../components/arrangement/sanityQuery";
import { ContributorRoleLabel } from "./cordata/roles";
import { SearchResponseFacet } from "@biblioteksentralen/search-models";
import { facets } from "./searchApi/facets";

type SearchResultsForCategory<T> = {
  items: T[];
  haveMore: boolean | undefined;
};

export type SearchResults = {
  works: SearchResultsForCategory<Work>;
  agents: SearchResultsForCategory<Agent>;
  events: SearchResultsForCategory<ResolvedEvent>;
};

export type SearchCategory = keyof SearchResults;

export const searchCategories = ["works", "events", "agents"] as const satisfies Readonly<SearchCategory[]>;

export const emptySearchResults = searchCategories.reduce(
  (results, dataType) => ({
    ...results,
    [dataType]: { items: [], haveMore: undefined },
  }),
  {} as SearchResults
);

export const isWork = (item: unknown): item is Work => typeof item === "object" && (item as Work)?.["type"] === "Work";

const isSearchResponseFacet = (item: unknown): item is SearchResponseFacet =>
  !!item && typeof item === "object" && typeof item?.["facet"] === "string" && Array.isArray(item?.["buckets"]);

export const isWorksResponse = (data: unknown): data is ElasticsearchWorksResponse =>
  typeof data === "object" &&
  Array.isArray(data?.["works"]) &&
  data?.["works"].every(isWork) &&
  (!data?.["facets"] || (Array.isArray(data["facets"]) && !!data["facets"].every(isSearchResponseFacet)));

export const isAgent = (item: unknown): item is Agent =>
  typeof item === "object" &&
  ((item as Agent)?.["type"] === "Person" || (item as Agent)?.["type"] === "CollectiveAgent");

export const isPerson = (agent: Pick<Agent, "type">): agent is Person => agent?.type === "Person";

export const isAgentsResponse = (data: unknown): data is ElasticsearchAgentResponse =>
  typeof data === "object" &&
  Array.isArray((data as ElasticsearchAgentResponse)?.["agents"]) &&
  (data as ElasticsearchAgentResponse)?.["agents"].every(isAgent);

export type SanityEventsResponse = { events: ResolvedEvent[] };

const searchFilters = ["books", "movies", "agents", "events"] as const;

export type SearchFilter = (typeof searchFilters)[number];

export const isSearchFilter = (item: unknown): item is SearchFilter =>
  typeof item === "string" && (searchFilters as readonly string[]).includes(item);

type CommonSearchRequestSchema = { searchQuery?: string; size?: number; from?: number };

const workSearchFilters = ["books", "movies"] as const satisfies Readonly<SearchFilter[]>;

export type WorkSearchFilter = (typeof workSearchFilters)[number];

export const isWorkSearchFilter = (item: unknown): item is WorkSearchFilter =>
  isSearchFilter(item) && (workSearchFilters as Readonly<SearchFilter[]>).includes(item);

export type SearchWorksRequestData = CommonSearchRequestSchema & {
  contributorId?: string;
  personSubjectId?: string;
  subjectId?: string;
  holdingsIds?: string[];
  filter?: WorkSearchFilter;
  publicationIds?: string[];
  aggregateManifestationIds?: string[];
  isMovie?: boolean;
  facets?: typeof facets;
  facetSize?: number;
};

export type SearchEventsRequestData = CommonSearchRequestSchema & {
  siteId: string;
  languageCode: LanguageCode;
};

export type SearchAgentsRequestData = CommonSearchRequestSchema;

export type SearchWorksByContributorRoleRequestData = CommonSearchRequestSchema & {
  agentId: string;
  roleLabel: ContributorRoleLabel;
};
