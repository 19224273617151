import { NextRouter, Url } from "next/dist/shared/lib/router/router";
import { useRouter } from "next/router";
import { omit } from "radash";
import { getCurrentCanonicalPath } from "./useCurrentCanonicalPath";

type State = string | number | boolean | string[];

/**
 * This helper function allows changing several URL parameters at the same time (which is not
 * possible using the hook below). Attempting to call setState from several useUrlState with
 * different keys does not work, maybe because the forst router.replace() interrupts the
 * remaining setState's?
 *
 * It would be preferrable to handle updating several parameters gracefully with useUrlState,
 * we can look into this later.
 */
export const getRouterStateUrl = (
  router: NextRouter,
  urlParameters: Record<string, State>,
  omittedParameterKeys: string[] = []
) => ({
  pathname: getCurrentCanonicalPath(router),
  query: { ...omit(router.query, ["site", ...omittedParameterKeys]), ...urlParameters },
});

export function useUrlState<T extends State>(
  defaultState: T,
  key: string
): [T, (state: T | undefined) => void, (state: T | undefined) => Url] {
  const router = useRouter();

  const getUrlToNewState = (state: T | undefined): Url => {
    // The states must be encoded/decoded to handle values like !"#$%&/()
    const newValue = typeof state === "string" ? decodeURIComponent(state) : JSON.stringify(state);
    return getRouterStateUrl(router, { [key]: newValue });
  };

  const setState = (state: T | undefined) => {
    router.replace(getUrlToNewState(state), undefined, { shallow: true, scroll: false });
  };

  try {
    const queryValue = router.query[key];

    if (typeof queryValue !== "string") return [defaultState, setState, getUrlToNewState];

    // TODO: vurderer å bruke zod for å validere data
    const stateFromUrl = (
      typeof defaultState === "string" ? decodeURIComponent(queryValue) : JSON.parse(queryValue)
    ) as T;

    if (typeof stateFromUrl !== typeof defaultState) {
      throw new Error("initialState and stateFromUrl have different types");
    }

    return [stateFromUrl, setState, getUrlToNewState];
  } catch (e) {
    console.error(e);
    return [defaultState, setState, getUrlToNewState];
  }
}
