import { SearchCategory } from "../types";

export const getSearchRequestParams = (data: Record<string, unknown>): RequestInit => ({
  method: "post",
  headers: { "Content-Type": "application/json", "Client-Identifier": "libry-content" },
  body: JSON.stringify(data),
});

export const searchParameterName = "s";

export const sizeParameterNames: Record<SearchCategory, string> = {
  works: "antallVerk",
  events: "antallArrangementer",
  agents: "antallPersoner",
};

export const defaultSizes = {
  works: 9,
  events: 3,
  agents: 4,
};
