import { ExternalLink } from "@biblioteksentralen/icons";
import { Flex } from "@biblioteksentralen/react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import InternalLink from "../../InternalLink";
import { PRIVACY_POLICY_PATH } from "../../site/PrivacyPolicyView";
import { FooterMenuList } from "./FooterMenuList";

export const SiteFooterMenuItems = ({ accesibilityStatementUrl }: { accesibilityStatementUrl: string | undefined }) => {
  const { t } = useTranslation();
  return (
    <FooterMenuList>
      <li>
        <InternalLink href={PRIVACY_POLICY_PATH} variant="plain">
          {t("Personvernerklæring")}
        </InternalLink>
      </li>
      {accesibilityStatementUrl && (
        <li>
          <Flex alignItems="center" display="inline-flex" gap=".3rem">
            <InternalLink href={accesibilityStatementUrl} variant="plain">
              {t("Tilgjengelighetserklæring")}
            </InternalLink>
            <ExternalLink width="0.75em" />
          </Flex>
        </li>
      )}
      <li>
        <InternalLink href="/nettstedkart" variant="plain">
          {t("Nettstedkart")}
        </InternalLink>
      </li>
    </FooterMenuList>
  );
};
