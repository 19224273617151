import { NextRouter, useRouter } from "next/router";

export const getCurrentCanonicalPath = ({ pathname }: NextRouter) => pathname.replace(/\/sites\/\[site\]/, "");

/**
 * The pathname looks different on server and client due to rewrites;
 * we choose the latter as canonical
 */
export const useCurrentCanonicalPath = () => {
  const router = useRouter();
  return getCurrentCanonicalPath(router);
};

export const useIsFrontPage = () => {
  return useCurrentCanonicalPath() === "";
};
